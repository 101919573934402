import { BREAKPOINTS, mediaQuery } from '@theme/responsive'
import styled from 'styled-components'

import { SvgLoader } from '@components/shared/SvgLoader'

export const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const StyledErrorImageWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 25px;
  ${mediaQuery(BREAKPOINTS.SMALL, `padding-left: 75px;`)}
`

export const StyledErrorImage = styled(SvgLoader)`
  width: 300px;
  ${mediaQuery(BREAKPOINTS.SMALL, `width: 500px;`)}
`

export const StyledErrorText = styled.div`
  justify-content: center;
`

export const StyledPageTitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.merriweather};
  font-style: normal;
  font-weight: 900;
  text-align: center;
  font-size: 40px;
  line-height: 50px;
  color: ${({ theme }) => theme.colors.tealDollhouse};
  ${mediaQuery(
    BREAKPOINTS.SMALL,
    `
      text-align: justify;
      font-size: 50px;
      line-height: 65px;
    `
  )}
`

export const StyledErrorMessage = styled.p`
  font-family: ${({ theme }) => theme.fonts.lato};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.tealRobots};
  ${mediaQuery(
    BREAKPOINTS.SMALL,
    `
      font-size: 24px;
      line-height: 29px;
      text-align: justify;
    `
  )}
`
export const StyledErrorContent = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
  ${mediaQuery(BREAKPOINTS.SMALL, `padding: 75px;`)}
  ${mediaQuery(BREAKPOINTS.LARGE, `padding-left: 60px;`)}
`

export const StyledErrorContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  ${mediaQuery(BREAKPOINTS.LARGE, `flex-direction: row;`)}
`

export const StyledActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 10px;
`
