import Error400Image from '@public/illustrations/error-400.svg'
import Error401Image from '@public/illustrations/error-401.svg'
import Error403Image from '@public/illustrations/error-403.svg'
import Error404Image from '@public/illustrations/error-404.svg'
import Error429Image from '@public/illustrations/error-429.svg'
import Error500Image from '@public/illustrations/error-500.svg'
import Error503Image from '@public/illustrations/error-503.svg'
import Error504Image from '@public/illustrations/error-504.svg'
import ErrorImage from '@public/illustrations/error.svg'
import { useIntl } from 'react-intl'

import { PageLayout } from '@components/layout/PageLayout'
import { StyledButton } from '@components/shared'

import { messages } from './messages'
import {
  StyledErrorMessage,
  StyledErrorContainer,
  StyledErrorText,
  StyledErrorImage,
  StyledPageTitle,
  StyledErrorImageWrapper,
  StyledErrorContent,
  StyledActions,
} from './styles'

export enum ErrorCode {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  TooManyRequests = 429,
  InternalServerError = 500,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
}

export interface IErrorPageProps {
  errorCode?: ErrorCode
}

export const ErrorPage: React.FC<IErrorPageProps> = ({ errorCode }) => {
  const intl = useIntl()

  let title = messages.headerTitle
  let message = messages.errorMessage
  let image = ErrorImage
  let showRefreshPage = false

  switch (errorCode) {
    case ErrorCode.BadRequest:
      message = messages.badRequestErrorMessage
      image = Error400Image
      showRefreshPage = true
      break

    case ErrorCode.Unauthorized:
      message = messages.forbiddenErrorMessage
      image = Error401Image
      break

    case ErrorCode.Forbidden:
      message = messages.forbiddenErrorMessage
      image = Error403Image
      break

    case ErrorCode.NotFound:
      title = messages.notFoundTitle
      message = messages.notFoundErrorMessage
      image = Error404Image
      break

    case ErrorCode.TooManyRequests:
      message = messages.tooManyRequestsErrorMessage
      image = Error429Image
      break

    case ErrorCode.InternalServerError:
      message = messages.serviceUnavailableMessage
      image = Error500Image
      showRefreshPage = true
      break

    case ErrorCode.ServiceUnavailable:
      message = messages.serviceUnavailableMessage
      image = Error503Image
      showRefreshPage = true
      break

    case ErrorCode.GatewayTimeout:
      message = messages.gatewayTimeoutErrorMessage
      image = Error504Image
      showRefreshPage = true
      break
  }

  return (
    <PageLayout
      title={intl.formatMessage(messages.pageTitle)}
      hasBreadcrumbs={false}
      hasAlternateHeader
    >
      <StyledErrorContainer>
        <StyledErrorImageWrapper>
          <StyledErrorImage {...image} />
        </StyledErrorImageWrapper>

        <StyledErrorContent>
          <span />

          <StyledErrorText>
            <StyledPageTitle>{intl.formatMessage(title)}</StyledPageTitle>
            <StyledErrorMessage>{intl.formatMessage(message)}</StyledErrorMessage>
          </StyledErrorText>

          <StyledActions>
            <StyledButton onClick={() => history.back()}>
              {intl.formatMessage(messages.goBackButtonLabel)}
            </StyledButton>

            {showRefreshPage && (
              <StyledButton primary onClick={() => window.location.reload()}>
                {intl.formatMessage(messages.refreshPageButtonLabel)}
              </StyledButton>
            )}
          </StyledActions>
        </StyledErrorContent>
      </StyledErrorContainer>
    </PageLayout>
  )
}
