import { createMessages } from '@lib/createMessage'

const prefix = 'components.errorpage'

export const messages = createMessages({
  prefix,
  messages: {
    pageTitle: {
      defaultMessage: 'Gembah - Error',
      description: 'Page title',
    },
    headerTitle: {
      defaultMessage: 'Oops! Looks like we’ve hit a snag.',
      description: 'Header title',
    },
    notFoundTitle: {
      defaultMessage: 'Oops! Page not found.',
      description: 'Not found header title',
    },
    errorMessage: {
      defaultMessage: 'We’re working to correct this issue.',
      description: 'General error message',
    },
    notFoundErrorMessage: {
      defaultMessage:
        'The page you are looking for does not exist. It might have been moved or deleted.',
      description: 'Not found error message',
    },
    badRequestErrorMessage: {
      defaultMessage: 'We’re unable to process your request',
      description: 'Bad request error message',
    },
    forbiddenErrorMessage: {
      defaultMessage:
        'You don’t have the proper credentials to access this page.  Please contact your admin to request permission.',
      description: 'Forbidden error message',
    },
    tooManyRequestsErrorMessage: {
      defaultMessage:
        'We have recieved too many requests from your computer.  If this issue continues, please contact the site owner.',
      description: 'Too many requests error message',
    },
    serviceUnavailableMessage: {
      defaultMessage: 'This is a problem on our side, try refreshing the page.',
      description: 'Internal server error message',
    },
    gatewayTimeoutErrorMessage: {
      defaultMessage:
        'The server encountered a temporary error and could not complete your request. Pleasey try again in a little bit.',
      description: 'Gateway timeout error message',
    },
    goBackButtonLabel: {
      defaultMessage: 'go back',
      description: 'Go back button label',
    },
    refreshPageButtonLabel: {
      defaultMessage: 'refresh the page',
      description: 'Refresh page button label',
    },
  },
})
